import {BreederApi, BreederSignupApi, LinkAccountDto} from "../api/generated/breeder-controller";
import ApiConfig from "../api/config/api-config";
import {BreederInfo} from "../api/generated/breeder-service";
import {BreederSearchResult} from "../api/generated/herd-breeder";
import {Result} from "../api/generated/lib-rest";
import {BreederRegistrationsApi, BreederRegistrationsController$JsonRegistration} from "../api/generated/registry-rest";

export type BreederDetails = {
    herdCode: string;
    username: string;
};

export interface IBreederService {
    search(searchTerm: string): Promise<BreederSearchResult[]>;
    info(): Promise<BreederInfo>;
    getDetails(breederId: string): Promise<BreederDetails>;
    listUnlinked(): Promise<string[]>;
    linkAccounts(linkAccountDto: LinkAccountDto): Promise<Result>;
    registeredAnimals(ownerId: string): Promise<BreederRegistrationsController$JsonRegistration[]>;
}

class BreederServiceImpl implements IBreederService {

    private _breederSignupApi: BreederSignupApi
    private _breederApi: BreederApi
    private _breederRegistrationsApi: BreederRegistrationsApi

    constructor(breederApi: BreederApi, breederSignupApi: BreederSignupApi, breederRegistrationsApi: BreederRegistrationsApi) {
        this._breederApi = breederApi;
        this._breederSignupApi = breederSignupApi;
        this._breederRegistrationsApi = breederRegistrationsApi;
    }

    async getDetails(breederId: string): Promise<BreederDetails> {
        return await this._breederApi.getDetails(breederId)
    }

    async search(searchTerm: string): Promise<BreederSearchResult[]> {
        const request = {
            name: searchTerm,
            email: searchTerm,
            herdCode: searchTerm
        };
        return (await this._breederApi.search(request)).items;
    }

    async info(): Promise<BreederInfo> {
        return await this._breederApi.getInfo();
    }

    async listUnlinked(): Promise<string[]> {
        return (await this._breederSignupApi.listUnlinked()).items;
    }

    async linkAccounts(linkAccountDto: LinkAccountDto): Promise<Result> {
        return this._breederSignupApi.linkAccount(linkAccountDto);
    }

    async registeredAnimals(ownerId: string): Promise<BreederRegistrationsController$JsonRegistration[]> {
        return (await this._breederRegistrationsApi.registeredAnimals(ownerId)).items;
    }
}


export const BreederService: IBreederService = new BreederServiceImpl(
    new BreederApi(new ApiConfig()),
    new BreederSignupApi(new ApiConfig()),
    new BreederRegistrationsApi(new ApiConfig()));
