// @flow
import React, {FunctionComponent} from 'react';
import {Box, Button, Link, Menu, MenuItem} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AuthService from "../../services/auth-service";
import {useHistory} from "react-router-dom";
import RouteService from "../../services/route-service";

interface AvatarButtonProps {
    username: string
}
const AvatarButton: FunctionComponent<AvatarButtonProps> = ({username}) => {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        history.push(RouteService.HOME);
        AuthService.doLogout();
    }

    return (
        <Box>
            <Button id="avatar-button"
                    aria-controls={open ? 'avatar-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                <Avatar>{username.length > 2 ? username.substring(0, 2) : username}</Avatar>
            </Button>
            <Menu
                id="avatar-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                <MenuItem>
                    <Link href={AuthService.getAccountUrl()} underline="none" color="black">Profil</Link>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default AvatarButton