// @flow
import React, {Fragment, FunctionComponent} from 'react';
import {Avatar, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import {BreederSearchResult} from "../../api/generated/herd-breeder";

interface UserListItemProps {
    breeder: BreederSearchResult
    onClick?: (breeder: BreederSearchResult) => void
    onDelete?: (breeder: BreederSearchResult) => void
};

interface ConditionalClickableListItemProps {
    breeder: BreederSearchResult,
    onClick?: (breeder: BreederSearchResult) => void,
}

const conditionalDeleteActionRender = (breeder: BreederSearchResult, onDelete?: (breeder: BreederSearchResult) => void) => onDelete
    ? <IconButton edge="end" aria-label="delete" onClick={() => onDelete(breeder)}><DeleteIcon/></IconButton>
    : null

const ConditionalClickableListItem: FunctionComponent<ConditionalClickableListItemProps> = ({breeder, onClick, children}) => {
        return onClick ? <ListItemButton onClick={() => onClick(breeder)}>{children}</ListItemButton> : <Fragment>{children}</Fragment>
    }

const BreederListItem: FunctionComponent<UserListItemProps> = ({breeder, onClick, children, onDelete}) => {
    return (<ListItem secondaryAction={conditionalDeleteActionRender(breeder, onDelete)}>
            <ConditionalClickableListItem breeder={breeder} onClick={onClick}>
                <ListItemAvatar>
                    <Avatar>
                        <InsertPhotoOutlinedIcon/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={breeder.username} secondary={!breeder.herdCode ? "" : `Herdkürzel: ${breeder.herdCode}`}/>
            </ConditionalClickableListItem>
        </ListItem>
    );
};

export default BreederListItem;
