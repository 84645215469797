import i18next from "i18next";
import {initReactI18next} from "react-i18next";

const resources = {
    de: {
        translation: {
            breeders: {
                filter: 'Züchter suchen'
            },
            treatmentType: {
                weighing: "Wiegung",
                othermedication: "Sonstige Medikation",
                other: "Sonstige Behandlung",
                spittest: "Spucktest",
                ultrasound: "Ultraschall",
                vaccination: "Impfung",
                bodyscore: "Bodyscore",
                vitaminsandminerals: "Vitamine und Mineralstoffe",
                antiparasitics: "Parasitenbehandlung"
            },
            herd: {
                filter: "Herde filtern"
            },
            breeder_create_success: "Konto erfolgreich angelegt",
            registered_at: "Registriert bei",
            breed: {
                HUACAYA: 'Huacaya',
                SURI: 'Suri',
            },
            sex: {
                MALE: 'männlich',
                FEMALE: 'weiblich',
                UNKNOWN: 'unbekannt',
            },
            color: {
                BAY_BLACK: 'Bay black',
                BEIGE: 'Beige',
                DARK_BROWN: 'Dunkelbraun',
                DARK_FAWN: 'Dark fawn',
                DARK_ROSE_GREY: 'Dark rose grey',
                DARK_SILVER_GREY: 'Dunkelsilbergrau',
                LIGHT_BROWN: 'Hellbraun',
                LIGHT_FAWN: 'Light fawn',
                LIGHT_ROSE_GREY: 'Light rose grey',
                LIGHT_SILVER_GREY: 'Hellsilbergrau',
                MEDIUM_BROWN: 'Mittelbraun',
                MEDIUM_FAWN: 'Medium fawn',
                MEDIUM_ROSE_GREY: 'Medium rose grey',
                MEDIUM_SILVER_GREY: 'Mittelsilbergrau',
                MULTICOLOR: 'Mehrfarbig',
                TRUE_BLACK: 'Echtschwarz',
                UNKNOWN: 'Unbekannt',
                WHITE: 'weiß',
            },
            identifier: {
                type: {
                    CHIP: 'Chip',
                    EAR_TAG: 'Ohrmarke',
                },
            },

        },
    }
};


i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: "de",
        interpolation: {
            escapeValue: false,
        },
    });

export default i18next;